.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color:
    #61dafb;
}

.title {
  display: flex;
  justify-content: center;
}

.detail-purchase {
  display: flex;
  min-height: 400px;
  flex-direction: column;
  justify-content: space-around;
}

.customer-purchase {
  display: flex;
  justify-content: space-between;
}

.customer-detail {
  display: flex;
  flex-direction: column;
}

.info-purchase {
  display: flex;
  min-width: 400px;
  justify-content: space-between;
  flex-direction: column;

}

.div-display {
  display: flex;
  justify-content: space-between;
}

.date-ref-title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.date-ref {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.info {
  display: flex;
  justify-content: space-between;
}

.vendor-detail {
  display: flex;
  justify-content: space-between;
  min-width: 260px;
}

.info-received {
  display: flex;
  min-width: 300px;
  justify-content: space-between;
}

.total-div {
  display: flex;
  flex-direction: row-reverse;
}

.detail-debtPayment {
  display: flex;
  min-height: 340px;
  flex-direction: column;
  justify-content: space-around;
}

.customer-debtPayment {
  display: flex;
  justify-content: space-between;
}

.info-debtPayment {
  display: flex;
  min-width: 400px;
  min-height: 164px;
  justify-content: space-between;
  flex-direction: column;

}

.statistique-card {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.stat-title {
  text-align: center;
}

.statistique-input {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.purchase-div {
  display: flex;
  justify-content: space-around;
  width: 59% !important;
}

.button-service {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.button-div {
  margin: 4px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}